
.no-padding {
    padding: 0 !important;
}
.hidden{
    display: none;
}
#dashboard_table table, #dashboard_table thead, #dashboard_table tr, #dashboard_table th, #dashboard_table tbody, #dashboard_table td {
    border: 0 !important;
}
/*temp*/
/*@media(min-width: 1024px) {*/
/*.m-topbar .m-topbar__nav.m-nav{*/
/*margin: unset !important;*/
/*}*/
/*.m-topbar__nav-wrapper{*/
/*margin-right: 60px;*/
/*}*/
/*.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--fluid {*/
/*padding: 0 50px 0 20px;*/
/*}*/
/*}*/
.padding-top-5 {
    padding-top: 5px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-0-auto {
    margin: 0 auto;
}
.text-align-left {
    text-align: left;
}
.font-weigh-400 {
    font-weight: 400 !important;
}

.m-nav .la-home:hover {
    color: #3f4047 !important;
}

.easy-transition {
    -webkit-transition: all 500ms cubic-bezier(0.250, 0, 0.755, 0.995); /* older webkit */
    -webkit-transition: all 500ms cubic-bezier(0.250, -0.020, 0.755, 0.995);
    -moz-transition: all 500ms cubic-bezier(0.250, -0.020, 0.755, 0.995);
    -o-transition: all 500ms cubic-bezier(0.250, -0.020, 0.755, 0.995);
    transition: all 500ms cubic-bezier(0.250, -0.020, 0.755, 0.995); /* custom */

    -webkit-transition-timing-function: cubic-bezier(0.250, 0, 0.755, 0.995); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.250, -0.020, 0.755, 0.995);
    -moz-transition-timing-function: cubic-bezier(0.250, -0.020, 0.755, 0.995);
    -o-transition-timing-function: cubic-bezier(0.250, -0.020, 0.755, 0.995);
    transition-timing-function: cubic-bezier(0.250, -0.020, 0.755, 0.995); /* custom */
}

input:focus, textarea:focus {
    border-color: #716aca !important;
}

.ac-color-text {
    color: #d12248;
}

/*Header*/
@media (min-width: 1025px) {
    #m_header_topbar {
        display: flex;
    }
}

/*#m_header_topbar .login-button {*/
/*position: relative;*/
/*width: 125px;*/
/*}*/

/*#m_header_topbar .login-button .btn-login {*/
/*position: absolute;*/
/*top: 50%;*/
/*transform: translateY(-50%);*/
/*color: #3d3b56;*/
/*font-size: 15px;*/
/*}*/

#m_header_topbar .login-button .btn-login:hover {
    color: #000;
}

#m_header_topbar .login-button .btn-login .flaticon-logout {
    padding-right: 5px;
    color: #3d3b56;
}

#m_header_topbar .m-topbar__user-profile .m-topbar__username {
    color: #3d3b56;
    font-size: 15px;
}

#m_header_topbar .m-topbar__user-profile .m-topbar__username:hover {
    color: #000;
}

@media (max-width: 768px) {
    .m-subheader {
        padding: 30px 20px 0 20px !important;
    }
}

/*@media (max-width: 1024px) {*/
/*.m-header__bottom {*/
/*margin-top: -20px !important;*/
/*}*/
/*}*/

.logo-img{
    display: inline-block;
    padding: 5px;
}
/*
@media (min-width: 1025px) {
    .m-stack--desktop {
        display: flex !important;
    }
}
*/

/*.m-header .m-header__bottom, .m-menu__item-here:before {*/
.m-menu__item-here:before {
    background: #000 !important;
}
/*.m-header__bottom{*/
/*padding: 15px 0;*/
/*}*/

.m-menu__item-here:before {
    color: #000;
}

/*.m-menu__link .m-menu__link-text, .m-menu__link .m-menu__link-icon {*/
/*color: #b0b0b0;*/
/*}*/

.m-subheader__title {
    text-transform: uppercase;
    font-weight: 400 !important;
}

/*#m_header .m-stack__item {
    display: flex;
    align-items: center;
}*/

/*@media screen and (min-width: 1025px) {*/
/*#m_header .m-menu__item {*/
/*padding: 0 20px;*/
/*}*/
/*}*/
/*@media screen and (min-width: 1025px) {*/
/*#m_header_menu ul {*/
/*display: inline-flex;*/
/*list-style: none;*/
/*padding: 0;*/
/*margin: 0;*/
/*}*/
/*}*/
/*.m-menu__link .m-menu__link-text:hover, .m-menu__link .m-menu__link-icon:hover,*/
/*#m_header_menu .m-menu__item--active .m-menu__link-icon, #m_header_menu .m-menu__link:hover > .m-menu__link-icon {*/
/*color: #fff;*/
/*}*/

/*.m-stack--desktop .m-stack__item--fluid {*/
/*display: flex;*/
/*}*/
@media screen and (min-width: 1025px) {
    #m_header_menu .m-menu__link-text {
        text-transform: uppercase;
        font-weight: 400;
    }
}

/*#m_header_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}*/

/*@media screen and (min-width: 1025px) {*/
/*#m_header .m-menu__link {*/
/*display: flex;*/
/*align-items: center;*/
/*}*/
/*}*/

#m_header .m-menu__link:hover {
    text-decoration: none;
}
#m_header .m-menu__link:hover i {
    color: #ffffff;
}

#m_header .m-menu__link-icon {
    padding-right: 10px;
}

.m-menu__link-text:before, .m-menu__link-text:after {
    content: '';
    position: absolute;
    background-color: #fff;
    z-index: -1;
    height: 2px;
    bottom: 20% !important; /*25%*/
}

.m-menu__link-text:before {
    width: 0%;
    left: 0;
    bottom: 0;
    transition: width ease 0.4s;
}

.m-menu__link-text:after {
    width: 100%;
    left: 0;
    bottom: 0;
    transition: all ease 0.6s;
}

.m-menu__link-text:hover:before {
    width: 100%;
}

.m-menu__link-text:after {
    left: 100%;
    width: 0%;
    transition: all ease 0.2s;
}

/*Reclamation details */
.reclamation_details .m-portlet.m-portlet--creative .m-portlet__head {
    height: 0;
}

.reclamation_details .padding-detail {
    padding-left: 10px;
    border-bottom: 1px dashed #e7e7e7;
}

.reclamation_details .fa-warning {
    color: #36a3f7;
}

.reclamation_details .padding-detail:last-child {
    border: 0;
}

.reclamation_details label {
    padding-top: 5px;
    font-weight: 300 !important;
    font-size: 15px !important;
}

/*.reclamation_details label:first-child {*/
    /*padding-top: 0;*/
/*}*/

.reclamation_details .m-portlet__head-label {
    font-weight: 400 !important;
    font-size: 1.2rem;
    color: #575962 !important;
}

.reclamation_details .m-portlet__head-label i {
    padding-right: 5px;
}

.reclamation_details .m-portlet__head-label {
    background: #fff;
    color: #000;
}

.reclamation_details .m-portlet__body .padding-detail {
    font-weight: 300;
}

.reclamation_details_images label, .reclamation_details_video label {
    font-weight: 400 !important;
    font-size: 1.2rem;
    color: #575962 !important;
}

.reclamation_details_images h4 {
    background: #fff;
    font-weight: 400 !important;
    font-size: 1.2rem;
    color: #575962 !important;
}

.reclamation_details_images .cutomer-image img {
    margin: 0 auto;
    cursor: pointer;
}

.reclamation_details_images .cutomer-image img:last-child {
    margin-right: 0;
}

/*Tables*/
.full-table-reclamation .m-portlet__head-caption {
    padding: 2.2rem 0 !important;
}

.full-table-reclamation .m-portlet__head {
    padding: 0 2.0rem !important;
}

.paging_simple_numbers {
    padding-bottom: 15px;
    padding-right: 15px;
}

.filters-list {
    width: 100%;
}

/*.filters-list .form-control,*/
/*.filters-list .m-list-search__form-input,*/
.all-filters input,
.all-filters select {
    border-color: #c1bfd0 !important;
    border: 1px solid;
    padding: 8px;
    border-radius: 0;
}
.filters-list{
    display: flex !important;
}

.filters-list .label-filter,
.all-filters label {
    color: #575962;
    font-weight: 400;
    font-size: 1rem;
    padding-right: 7px;
}

.filters-list .select-filter {
    display: flex;
    align-items: center;
}

.filters-list .select-filter label {
    margin-bottom: 0;
}

/*.filters-list .id-input-filter input {*/
/*width: 75%;*/
/*}*/
.filters-list .bt-filtriraj {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 0 !important;
}
.filters-list .code-input-filter input {
    width: 65%;
}
@media(max-width:768px ){
    .filters-list .code-input-filter input {
        width: auto;
    }
    .filters-list .id-input-filter input {
        width: auto;
    }
    #ReclamationTicketFilterType_filterStatus{

    }
    .bt-filtriraj{
        justify-content: center;
    }
    /*.filter-result .text-right{*/
    /*text-align: center !important;*/
    /*}*/
}

.all-filters .bt-filtriraj {
    background: #fff;
    border: 1px solid #575962 !important;
    box-shadow: unset !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #575962;
    box-sizing: unset !important;
    /*Chrome fix*/
    max-height: 20px;
}

.all-filters .bt-filtriraj:hover,
.all-filters .bt-filtriraj:focus {
    background: #575962;
    color: #fff;
}

@media (min-width: 768px) {
    .filters-list .bt-filtriraj {

    }
}

/*Mac fix*/
.min-width-75 {
    min-width: 75%;
}

/*.filter-result .filter-by {*/
/*background-color: #f4f5f8;*/
/*border-radius: 7px;*/
/*padding: 3px 8px;*/
/*}*/

.filter-result .filter-btn-reset .btn {
    padding: 5px 14px;
}

#dashboard_table a:first-child {
    padding-left: 10px;
}

#dashboard_table a .sort-icon-table {
    color: #575962;
    padding-left: 3px;
}

#dashboard_table .active .sort-icon-table {
    color: #36a3f7;
}

#dashboard_table thead tr th, #user_table thead tr th {
    border-top: 1px solid #f4f5f8;
    padding: 16px 6px !important;
}

#dashboard_table .reclam-font-color {
    font-weight: 500;
}

#dashboard_table .table-hover tbody tr:hover, #user_table .table-hover tbody tr:hover {
    background-color: #f7f8fa;
}

#dashboard_table table thead tr, #user_table table thead tr {
    background-color: #f2f3f8;
}

#dashboard_table table tbody tr:nth-child(even), #user_table table tbody tr:nth-child(odd) {
    background-color: #faf9fc;;
}

#dashboard_table table tbody tr a, #user_table table tbody tr a {
    color: #000;
}

#dashboard_table table tbody tr a:hover, #user_table table tbody tr a:hover {
    background: #000;
    color: #fff;
}

#dashboard_table .letter-circle {
    display: inline-flex;
    align-items: center;
    height: 71px;
}

#dashboard_table .letter-circle .circle {
    min-width: 40px;
    min-height: 40px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-weight: 500;
    margin-right: 10px;
}

#dashboard_table .letter-circle .color-d {
    background-color: #E26A6A !important;
    color: #fff !important;
}

#dashboard_table .letter-circle .color-a {
    background-color: #4B77BE !important;
    color: #fff !important;
}

#dashboard_table .letter-circle .color-f {
    background-color: #1BA39C !important;
    color: #fff !important;
}

#dashboard_table .letter-circle .color-p {
    background-color: #c5b96b !important;
    color: #fff !important;
}

/*Status*/
#dashboard_table .m-badge--metal, .reclamation_details .m-badge--metal {
    background-color: #d05454;
}

#dashboard_table .m-badge--brand, .reclamation_details .m-badge--brand {
    background-color: #f3c200;
}

#dashboard_table .m-badge--success, .reclamation_details .m-badge--success {
    background-color: #4DB3A2;
}

#dashboard_table .m-badge--info, .reclamation_details .m-badge--info {
    background-color: #3faba4;
}

/*pagination*/
.pagination {
    justify-content: flex-end;

}

.pagination .page-item.disabled > .page-link {
    opacity: .6;
}

.pagination .page-item:first-child {
    margin-left: unset;
}

.pagination .page-item {
    margin-left: 4px;
}

.pagination > li > a, .pagination > li > span {
    line-height: 1.2;
}

.pagination .page-link {
    border-radius: 50% !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    min-width: 2.25rem;
    vertical-align: middle;
    padding: .5rem;
    text-align: center;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    color: #575962;
}

.pagination .page-item:hover > .page-link, .pagination .page-item.next > .page-link:hover, .pagination .page-item.previous > .page-link:hover {
    background: #000;
    color: #fff;
    border: 1px solid #000;
}

.pagination .active .page-link, .pagination .active .page-link:hover, .pagination > .active > a, .pagination > .active > a:hover
.pagination .active .page-link:focus, .pagination > .active > a, .pagination > .active > a:focus {
    color: #fff !important;
    background-color: #000;
    border-color: #000;
}

.pagination .page-item.disabled .page-link, .pagination .page-item.disabled:hover .page-link, .pagination .page-item.disabled .page-link:hover, .pagination .disabled:hover {
    border: 0;
    cursor: unset;
    background-color: #fff;
    color: #575962;
}

.pagination .page-item.previous > .page-link, .pagination .page-item.next > .page-link {
    background: #ebe9f2;
    border: 0;
}

.pagination .page-item.next > .page-link:hover, .pagination .page-item.previous > .page-link:hover {
    background: #000;
    color: #fff;
}

/*Create ticket*/
.create-ticket-page .create-ticket-page input textarea {
    border-radius: 3px;
    border: 1px solid #9699a2;
    color: #575962;
}

/*all padding*/
.step-1, .step-2, step-3, .step-4 {
    padding: 0 !important;
}

/*Step 1*/
.step-1 .bottom-border {
    text-align: center;
}

.step-1 .product-details {
    display: flex;
}

.step-1 label {
    font-size: 14px;
    font-weight: 400;
    position: relative;
    padding-right: 15px;
}

/*.step-1 label:before {*/
    /*content: "*";*/
    /*font-style: normal;*/
    /*font-weight: normal;*/
    /*text-decoration: inherit;*/
    /*font-size: 14px;*/
    /*color: #575962;*/
    /*position: absolute;*/
    /*left: -10px;*/
/*}*/

@media (min-width: 1024px) {
    .step-1 .step1-margin {
        margin-left: -100px;
        margin-top: 48px;
    }

    .step-1 .step2-margin {
        margin-top: 48px;
        padding: 0;
    }
}

#finder_product_name {
    background-color: #f4f5f8;
    padding: 10px;
}

.step-1 .step1-h3 {
    font-size: 14px;
    font-weight: 400;
}

.step-1 .product-image img {
    margin: 0 auto;
}

.step-1 h3 {
    font-size: 16px;
    padding-right: 10px;
}

.step-1 .bottom-border {
    position: relative;
    padding-bottom: 15px;
}

.step-1 .bottom-border:after {
    content: "";
    width: 80%;
    left: 10%;
    height: 1px;
    background-color: #e9ecef;
    position: absolute;
    display: block;
    bottom: 0;
}

@media (min-width: 768px) {
    .step1-margin-left-30 {
        margin-left: 30px;
    }
}

.step-1 .step1-help-text {
    font-size: 12px;
    color: #333;
    /*font-family: "Roboto";*/
}

.step-1 .step1-help-text a {
    color: #000;
    font-weight: bolder;
}

.step-1 .step1-help-text a:hover {
    text-decoration: unset;
}
.step-1 .step1-term {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-top: 20px;
}
.step-1 .step1-term input{
    margin-right: 15px;
}
.step-1 .terms-btn {
    cursor: pointer;
    font-family: Poppins;
    font-weight: 500;
    /*Chrome fix*/
    background: white;
    border: 0 solid white;
    -webkit-appearance: unset;
    color: #000;
}
/*step 2*/
.step-2-title h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}

.step-2 label {
    color: #3f4047;
    font-size: 14px;
    font-weight: 400;
}

.step-2 .display-flex {
    display: flex;
    align-content: center;
    align-items: center;
}

.step-2-user {
    font-weight: 400;
}

.step-2-disable {
    background-color: #e7e7e7;
}

@media (max-width: 991px) {
    .step-2-spacer {
        display: none;
    }
}
.step-2 textarea{
    width: 100%;
}

/*Step 3*/
.step-3{
    margin: 0 auto !important;
}
.step-3 label {
    font-size: 14px;
    font-weight: 400;
}

.step-3 .account-num input {
    max-width: 200px;
    padding: 5px !important;
}

.step-3 .client-request select {
    border: 1px solid #9699a2;
    height: auto !important;
    border-radius: 3px !important;
    font-weight: bolder;
    font-size: 14px !important;
}

.step-3 textarea {
    width: 100%;
    min-width: 50%;
    padding: 5px !important;
    margin-bottom: 5px;
}

.step-3 .btn-outline-accent {
    border-color: #3f4047;
    margin-bottom: 10px;
}
.step-3 .btn-outline-accent:active,
.step-3 .btn-outline-accent:focus {
    border-color: #3f4047 !important;
    color: #ffffff;
    background-color: #3f4047 !important;
}

.step-3 .btn-outline-accent + .step-3 .btn-outline-accent {
    margin-top: 10px;
}

.step-3 .btn-outline-accent:hover {
    border-color: #3f4047;
    background: #3f4047;
}

.step-3 #ReclamationTicketRequestType_images input {
    margin-bottom: 10px;
}
.step-3 #ReclamationTicketRequestType_videos input {
    margin-bottom: 10px;
}
.step-3 #ReclamationTicketRequestType_videos label {
    display: none;
}
.step-3 .account-num input,
.step-3 .client-request select {
    max-width: 100% !important;
    padding: .65rem 1rem !important;
    line-height: 1.25 !important;
}
.step-3 .receipt-not-included{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 63px;
}
.step-3 .receipt-not-included label{
    margin-bottom: 0 !important;
}
.step-3 .receipt-not-included input{
    margin: 10px;
}
.step-3 .m-form__help{
    font-size: 13px;
    color: #333;
}
.step-3 .flaticon-exclamation-1{
    padding-left: 10px;
}
@media screen and (max-width: 767px) {
    .step-3 .receipt-not-included {
        padding-top: 4px;
        margin-bottom: 10px;
    }
    .steps-heading {
        margin-bottom: 26px;
    }
    /*.padding-added {*/
        /*padding-bottom: 20px;*/
    /*}*/
    .create-ticket-page .m-portlet .m-portlet__body {
        padding: 2.2rem 10px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
    .step-3 .receipt-not-included {
        padding-top: 6px;
        margin-bottom: 10px;
    }
    .steps-heading {
        margin-bottom: 26px;
    }
    /*.padding-added {*/
        /*padding-bottom: 20px;*/
    /*}*/
    /*.client-request {*/
        /*padding-bottom: 20px;*/
    /*}*/
}
/*Step 4*/
.step-4{
    justify-content: center !important;
}
.step-4 #m_accordion_1 {
    width: 100%;
}
.step-4 label {
    margin-bottom: 0;
    font-weight: 400;
    font-family: Poppins;
}
.step-4 .accordian-padding {
    padding-top: calc(.65rem + 1px);
    padding-bottom: calc(.65rem + 1px);
}

.step-4 .m-accordion__item-body label {
    font-size: 14px;
    font-weight: 400;
}
.step-4 .acc-image{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.step-4 .acc-image .acc-single-image{
    max-width: 150px;
    padding: 0 5px;
}
.step-4 .acc-image img{
    width: 100%;
    height: auto;
}
.create-ticket-page .form-control-feedback {
    position: relative;
    top: unset;
    width: unset;
    height: unset;
    color: #f4516c;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
}

@media (min-width: 768px) {
    .padding-33 {
        padding-left: 33%;
    }
}

.create-ticket-page .form-control-feedback ul {
    margin-bottom: 0;
    padding: 0;
}

.create-ticket-page .form-control-feedback ul li {
    list-style: none;
}

.succ-title {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.succ-content {
    display: block;
    justify-content: center;
    align-items: center;
}

.succ-req {
    font-weight: 300;
}

.succ-img img {
    width: 40px;
}

.succ-text {
    font-size: 21px;
}

.succ-icon i {
    color: #c1bfd0;
    font-size: 22.4px;
}

.succ-icon i:hover, .succ-icon i:focus {
    color: #000;
    text-decoration: unset;
}

/*Success check*/
.check_mark {
    width: 80px;
    height: 130px;
    margin: 0 auto;
}

.hide {
    display: none;
}

.sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
}

.sa-icon.sa-success {
    border-color: #4CAF50;
}

.sa-icon.sa-success::before, .sa-icon.sa-success::after {
    content: '';
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sa-icon.sa-success::before {
    -webkit-border-radius: 120px 0 0 120px;
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
}

.sa-icon.sa-success::after {
    -webkit-border-radius: 0 120px 120px 0;
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0px 60px;
    transform-origin: 0px 60px;
}

.sa-icon.sa-success .sa-placeholder {
    width: 60px;
    height: 60px;
    border: 4px solid rgba(76, 175, 80, .5);
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: 10px;
    top: 5px;
    z-index: 2;
}

.sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: white;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
}

.sa-icon.sa-success {
    border-color: transparent \9;
}

.sa-icon.sa-success .sa-line.sa-tip {
    -ms-transform: rotate(45deg) \9;
}

.sa-icon.sa-success .sa-line.sa-long {
    -ms-transform: rotate(-45deg) \9;
}

.animateSuccessTip {
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
}

@-webkit-keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@-webkit-keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@-webkit-keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}

@keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}
.succ-note{
    font-size: 14px;
}
@media (min-width: 768px){
    .succ-note{
        padding-left: 20% !important;
        padding-right: 20% !important;
    }
}
.succ-note-title{
    text-align: left;
}
.succ-note-text{
    text-align: justify;
}
.succ-note-text a{
    color: #555555;
    text-decoration: underline;
}
.succ-note-text a:hover {
    color: #000000;
}

/*/end check*/

.m-form__control-static {
    padding-top: .65rem; /*Copy/Paste*/
    display: inline-block; /*Copy/Paste*/
}

/*Buttons*/
/*margin for all */
.create-ticket-page .m-wizard__form .btn-next, .create-ticket-page .m-wizard__form .btn-back, .create-ticket-page .m-wizard__form .btn-reset, .create-ticket-page .m-wizard__form .btn-finish {
    margin: 30px 0 !important;
}

.create-ticket-page .m-wizard__form .m-form__actions .btn {
    margin: 5px;
}

.create-ticket-page .m-wizard__form input, .create-ticket-page textarea {
    /* padding: 2px 5px;*/
    padding: .65rem 1rem;
    border: 1px solid #9699a2;
    border-radius: 3px;
}

.create-ticket-page .has-danger input, .create-ticket-page .has-danger textarea {
    border: 1px solid #f4516c;
}

.create-ticket-page .m-wizard__form .btn-next {
    color: #fff;
    background: linear-gradient(135deg, #34bfa3 30%, #34bfa3 100%);
    border-color: #34bfa3;
}

.create-ticket-page .m-wizard__form .btn-next:hover, .create-ticket-page .m-wizard__form .btn-next:focus, .create-ticket-page .m-wizard__form .btn-next:active {
    background: linear-gradient(135deg, #79c8b8 30%, #79c8b8 100%);
    border-color: #79c8b8;
}

.create-ticket-page .m-wizard__form .btn-back {
    color: #fff;
    background: linear-gradient(135deg, #00a3b6 30%, #00a3b6 100%);
    border-color: #00a3b6;
    margin-left: 15px !important;
}

.create-ticket-page .m-wizard__form .btn-back:hover, .create-ticket-page .m-wizard__form .btn-back:focus, .create-ticket-page .m-wizard__form .btn-back:active {
    background: linear-gradient(135deg, #00c0d7 30%, #00c0d7 100%);
    border-color: #00c0d7;
}

.create-ticket-page .m-wizard__form .btn-reset {
    color: #fff;
    background: linear-gradient(135deg, #b0b0b0 30%, #b0b0b0 100%);
    border-color: #b0b0b0;
    padding: .75rem 1rem;
}

.create-ticket-page .m-wizard__form .btn-reset:hover, .create-ticket-page .m-wizard__form .btn-reset:focus, .create-ticket-page .m-wizard__form .btn-reset:active {
    background: linear-gradient(135deg, #000 30%, #000 100%);
    border-color: #000;
    color: #fff;
}

.create-ticket-page .m-wizard__form .btn-finish {
    color: #fff;
    background: linear-gradient(135deg, #34bfa3 30%, #34bfa3 100%);
    border-color: #1BBC9B;
}

.create-ticket-page .m-wizard__form .btn-finish:hover, .create-ticket-page .m-wizard__form .btn-finish:focus, .create-ticket-page .m-wizard__form .btn-finish:active {
    background: linear-gradient(135deg, #2ca189 30%, #2ca189 100%);
    border-color: #2ca189;
}

/*step 3*/

.create-ticket-page .m-wizard__form #ReclamationTicketRequestType {
    margin: 0 auto;
    width: 100%;
}

@media (min-width: 768px) {
    .create-ticket-page .m-wizard__form #ReclamationTicketRequestType {
        width: 75%;
    }
}

/*step 4*/
.create-ticket-page .m-wizard__form #ReclamationTicketConfirmationType div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    padding: 0 30px;
}

.create-ticket-page .m-wizard__form #ReclamationTicketConfirmationType_termsAndConditions {
    margin-right: 5px;
}

.create-ticket-page .m-wizard__form #ReclamationTicketRequestType div {
    /*width: 45%;*/
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.create-ticket-page .m-wizard__form #ReclamationTicketRequestType div input, .create-ticket-page .m-wizard__form #ReclamationTicketRequestType div select,
.create-ticket-page .m-wizard__form #ReclamationTicketRequestType div textarea {
    width: 45%;
}

/*buttons on create page*/
.craue_formflow_buttons {
    padding: 0 15px !important;
}

.btn-ac-black {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.btn-ac-black:hover, .btn-ac-black:focus {
    background-color: #2a2a2a;
    border-color: #2a2a2a;
    color: #fff;
}

.login-bg {
    background-image: url("/images/bg-5black.jpg");
}

.login-bg .m-login__title {
    color: #000 !important;
    text-transform: uppercase;
}
@media (max-width: 480px) {
    .m-login__wrapper .m-login__container {
        width: 100% !important;
        margin: 0 auto !important;
    }
}
.status-box {
    background-color: #F7941D;
    display: inline-block;
    width: 12px;
    height: 12px;
}

.status-box.cancel {
    background-color: #e02612;
}

.status-box.finished {
    background-color: #1b9448;
}

.status-box.note {
    background-color: #0d638f;
}

.m-menu__link-text {
    position: relative;
    text-decoration: none;
    display: inline-block;
    color: #000;
    padding: 0 1px;
    transition: color ease 0.3s;
    font-size: 14px !important;
}

.empty-list {
    border: 1px solid #000;
}

/*lightbox*/
.modal-dialog.gallery {
    display: grid;

    justify-content: center;
}

.modal-dialog.gallery {
    margin-top: 10% !important;
    /* max-width: 100% !important;*/
}

.modal-body.gallery {
    position: relative;
    padding: 5px !important;
    background: #ccc;
    /*test*/
    display: flex;
    justify-content: center;
}

@media (min-width: 320px) {
    .modal-body.gallery {
        min-width: 300px;
    }
}

@media (min-width: 414px) {
    .modal-body.gallery {
        min-width: 400px;
    }
}

@media (min-width: 640px) {
    .modal-body.gallery {
        min-width: 620px;
    }
}

@media (min-width: 768px) {
    .modal-body.gallery {
        min-width: 765px;
    }
}

@media (min-width: 1024px) {
    .modal-body.gallery {
        min-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-body.gallery {
        min-width: 1000px;
    }
}

.close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
}

#image {
    min-height: 200px;
}

/*Hamburger menu*/
.hamburger, .cross {
    display: none;
}

/*responsive menu*/

@media (max-width: 992px) {
    /*.m-header__bottom {*/
    /*display: none;*/
    /*}*/

    /*#m_header_nav {*/
    /*height: 60px;*/
    /*border-bottom: #dddddd 1px solid;*/
    /*}*/

    /*.hamburger, .cross {*/
    /*display: block;*/
    /*}*/

    body {
        margin: 0;
        width: 100%;
        height: 100vh;
        background: #ffffff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*header, #m_header {*/
    /*width: 100% !important;*/
    /*background: #ffffff !important;*/
    /*height: 60px !important;*/
    /*line-height: 60px !important;*/
    /*border-bottom: 1px solid #dddddd !important;*/
    /*}*/

    /*.m-stack--desktop {*/
    /*display: flex;*/
    /*}*/

    /*.m-header--fixed-mobile .m-body {*/
    /*padding-top: 90px !important;*/
    /*}*/

    /*.hamburger {*/
    /*background: none;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 0;*/
    /*line-height: 45px;*/
    /*padding: 5px 15px 0px 15px;*/
    /*color: #999;*/
    /*border: 0;*/
    /*font-size: 1.4em;*/
    /*font-weight: bold;*/
    /*cursor: pointer;*/
    /*outline: none;*/
    /*z-index: 10000000000000;*/
    /*}*/

    /*.cross {*/
    /*background: none;*/
    /*position: absolute;*/
    /*top: 0px;*/
    /*right: 0;*/
    /*padding: 7px 15px 0px 15px;*/
    /*color: #999;*/
    /*border: 0;*/
    /*font-size: 3em;*/
    /*line-height: 65px;*/
    /*font-weight: bold;*/
    /*cursor: pointer;*/
    /*outline: none;*/
    /*z-index: 10000000000000;*/
    /*}*/

    /*.h-menu {*/
    /*z-index: 1000000;*/
    /*width: 100%;*/
    /*background: #f1f1f1;*/
    /*position: absolute;*/
    /*text-align: center;*/
    /*font-size: 16px;*/
    /*top: 60px;*/
    /*border-color: #ebedf2;*/
    /*}*/

    /*.h-menu, .h-menu .m-menu__item .m-menu__link-text, .h-menu .m-topbar__username,*/
    /*.m-menu__link .m-menu__link-text, .m-menu__link .m-menu__link-icon {*/
    /*font-weight: 400 !important;*/
    /*color: #3f4047 !important;*/
    /*font-size: 16px;*/
    /*}*/

    /*.h-menu .logout-btn, .h-menu .flaticon-logout, .h-menu .flaticon-user-ok {*/
    /*font-weight: 400 !important;*/
    /*color: #3f4047 !important;*/
    /*font-size: 16px !important;*/
    /*}*/

    /*.h-menu span {*/
    /*padding: 0;*/
    /*}*/

    /*.h-menu i {*/
    /*padding-right: 5px !important;*/
    /*}*/

    /*.h-menu ul {*/
    /*margin: 0;*/
    /*padding: 0;*/
    /*list-style-type: none;*/
    /*list-style-image: none;*/
    /*}*/

    /*.h-menu li {*/
    /*display: flex;*/
    /*padding: 15px 0 15px 20px;*/
    /*border-bottom: #dddddd 1px solid;*/
    /*}*/

    /*.h-menu li:hover {*/
    /*display: flex;*/
    /*background: #ffffff;*/
    /*padding: 15px 0 15px 20px;*/
    /*border-bottom: #dddddd 1px solid;*/
    /*}*/

    /*.h-menu ul li a {*/
    /*text-decoration: none;*/
    /*margin: 0px;*/
    /*color: #666;*/
    /*}*/

    /*.h-menu ul li a:hover {*/
    /*color: #666;*/
    /*text-decoration: none;*/
    /*}*/

    /*.h-menu a {*/
    /*text-decoration: none;*/
    /*color: #666;*/
    /*}*/

    /*.h-menu a:hover {*/
    /*text-decoration: none;*/
    /*color: #666;*/
    /*}*/

    .glyphicon-home {
        color: white;
        font-size: 1.5em;
        margin-top: 5px;
        margin: 0 auto;
    }

    /*header {*/
    /*display: inline-block;*/
    /*font-size: 12px;*/
    /*}*/

    /*span {*/
    /*padding-left: 20px;*/
    /*}*/

    a {
        color: #336699;
    }
}

/*Steps responsive*/
@media (max-width: 992px) {
    .m-wizard__steps .m-wizard__step-number span, .m-wizard__steps .m-wizard__step-number span span {
        padding: 0;
    }

    .create-ticket-page .m-wizard__form .btn-back {
        margin-left: 0 !important;
    }

    .create-ticket-page .m-wizard__form .btn-next, .create-ticket-page .m-wizard__form .btn-back, .create-ticket-page .m-wizard__form .btn-reset, .create-ticket-page .m-wizard__form .btn-finish {
        margin: 15px 0 !important;
    }

    #ReclamationTicketRequestType_images span {
        padding: 0;
    }

    .create-ticket-page .m-wizard__form .btn-finish, .create-ticket-page .m-wizard__form .btn-next {
        width: 100%;
    }

    .create-ticket-page .m-wizard__form .btn-finish span, .create-ticket-page .m-wizard__form .btn-next span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}

/*Back to top*/

.cd-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 40px;
    right: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    /* image replacement properties */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    /*background: rgba(232, 98, 86, 0.8) url(/images/cd-top-arrow.svg) no-repeat center 50%;*/
    background: #b0b0b0 url(/images/cd-top-arrow.svg) no-repeat center 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s, background-color .3s 0s;
    transition: opacity .3s 0s, visibility 0s .3s, background-color .3s 0s;
}

.cd-top.cd-top--show,
.cd-top.cd-top--fade-out,
.cd-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s, background-color .3s 0s;
    transition: opacity .3s 0s, visibility 0s 0s, background-color .3s 0s;
}

.cd-top.cd-top--show {
    /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.cd-top.cd-top--fade-out {
    /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
    opacity: .5;
}

.cd-top:hover {
    background-color: #575962;
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    .cd-top {
        right: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .cd-top {
        height: 40px;
        width: 40px;
        right: 30px;
        bottom: 30px;
    }
}





.full-table-reclamation .m-portlet__head > .all-filters > .row {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;
}
.full-table-reclamation .m-portlet__head > .all-filters .select-filter label {
    margin-right: 4px;
}
.full-table-reclamation .m-portlet__head > .all-filters .select-filter select {
    width: calc(100% - 124px);
}
.full-table-reclamation .m-portlet__head > .all-filters .filters-list label {
    min-width: 120px;
    max-width: 120px;
    text-align: right;
    padding-right: 14px;
}
.full-table-reclamation .m-portlet__head > .all-filters .filters-list input {
    width: calc(100% - 124px);
    margin-bottom: 10px;
}
.full-table-reclamation .m-portlet__head > .all-filters .filters-list input[type='submit'] {
    width: unset;
}
.all-filters > .filter-result {
    /*border-top: 1px solid #ebedf2;*/
    margin: 0 15px !important;
}
.all-filters > .filter-result > div {
    padding: 0;
}
.horizontal-line {
    width: calc(100% - 30px);
    margin: 10px auto;
    height: 1px;
    background-color: #ebedf2;
}
.filter-result .chosen-filter {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #f4f5f8;
    border-radius: 7px;
    padding: 3px 12px;
    display: inline-block;
    margin-right: 4px;
}
.filter-result > div:first-child > div:first-child {
    font-weight: 500;
    text-transform: uppercase;
    color: #777777;
}
.filter-result .chosen-filter label {
    margin: 0;
}
.full-table-reclamation #dashboard_table .img-responsive {
    max-width: 50px;
    border: 1px solid #f6f6f6;
    float: right;
}
.reclamation_details .m-portlet__head {
    height: 0;
}
.reclamation_details .product-image .img-responsive {
    display: block;
    margin: 0 auto;
}
.reclamation_details_images .m-portlet__body .gallery.cutomer-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.reclamation_details_images .m-portlet__body .gallery > div {
    width: 18%;
    margin-right: 2.5%;
    text-align: center;
}
.reclamation_details_images .m-portlet__body .gallery > div:nth-child(5){
    margin-right: 0;
}
.reclamation_details_images .m-portlet__body .gallery img {
    max-width: 100%;
}
#m_header_topbar .login-button .btn-login {
    vertical-align: middle;
}
.m-header__top .m-stack--desktop .m-brand {
    min-width: 100%;
}
.m-topbar .m-topbar__nav.m-nav > .m-nav__item {
    padding: 0 2px !important;
}
.m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link .m-nav__link-icon .m-nav__link-icon-wrapper {
    width: fit-content;
    width: -moz-fit-content;
    border-radius: 60px !important;
}
.m-page {
    display: block !important;
}
footer {
    padding: 0 30px !important;
}
@media screen and (max-width: 559px) {
    .full-table-reclamation .m-portlet__head > .all-filters .filters-list label {
        display: block;
        text-align: left;
        margin-bottom: 4px;
    }
    .full-table-reclamation .m-portlet__head > .all-filters .filters-list input[type="submit"] {
        float: unset !important;
        margin: 10px auto 0 auto;
    }
    .filters-list .select-filter {
        flex-direction: column;
        align-items: flex-start;
    }
    .full-table-reclamation .m-portlet__head > .all-filters .select-filter select,
    .full-table-reclamation .m-portlet__head > .all-filters .filters-list input {
        width: 100%;
    }
    .full-table-reclamation .m-portlet__head {
        padding: 0 !important;
    }
    .min-width-75 {
        min-width: unset;
    }
    .filter-result .text-right {
        text-align: center !important;
    }
}
@media screen and (max-width: 575px) {
    .filter-result .min-width-75 {
        text-align: center;
        margin-bottom: 14px;
    }
    .filter-result .chosen-filter {
        margin-right: 0;
        margin-bottom: 4px;
    }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .filter-result .min-width-75 {
        min-width: unset;
    }
    .filter-result .text-right {
        text-align: right !important;
    }
    .filter-result .chosen-filter {
        margin-right: 4px;
        margin-bottom: 4px;
    }
}
@media screen and (max-width: 768px) {
    #m_header_topbar .m-nav--inline {
        margin-right: 14px !important;
    }
    #m_header_topbar .m-nav--inline li {
        display: block;
        height: 30px;
    }
    #m_header_topbar .m-nav--inline li:first-child {
        margin-bottom: 4px;
    }
    #m_header_topbar .m-nav--inline li .m-btn.m-btn--pill {
        border: none;
        padding: 0;
    }
    .m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link {
        margin: unset;
    }
    #m_header_topbar .m-nav--inline li:last-child > a.m-nav__link {
        margin-left: auto;
    }
    #m_header_topbar .m-nav--inline li:last-child {
        margin-bottom: 6px;
    }
    .m-header--fixed-mobile .m-topbar {
        top: -70px;
    }
}
@media screen and (max-width: 992px) {
    .full-table-reclamation .m-portlet__head > .all-filters .select-filter {
        margin-bottom: 10px;
    }
    .full-table-reclamation .m-portlet__head > .all-filters .select-filter label {
        margin-right: 3px;
    }
    /*.m-header .m-header__bottom {*/
    /*background: #000;*/
    /*}*/
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .login-button {
        margin-right: 10px;
    }
}
@media screen and (max-width: 1024px) {
    .m-subheader {
        padding: 30px 20px 0 20px;
    }
    /*.m-header--fixed-mobile .m-topbar {*/
    /*margin-top: 0 !important;*/
    /*top: -50px !important;*/
    /*}*/
    .step-2-disable {
        width: 100%;
        height: 100%;
        padding: 6px 14px;
    }
    .btn.m-btn--custom.btn-login {
        padding: 7px 24px;
        height: unset !important;
    }
    #m_header_menu .m-menu__item.m-menu__item--active a {
        background-color: #000000;
    }
    .m-menu__item .m-menu__link-text,
    .m-menu__item .m-menu__link-icon {
        color: #000000 !important;
    }
    .m-menu__item.m-menu__item--active .m-menu__link-text,
    .m-menu__item.m-menu__item--active .m-menu__link-icon {
        color: #ffffff !important;
    }
    #m_aside_header_menu_mobile_close_btn {
        background-color: unset !important;
    }
    #m_aside_header_menu_mobile_close_btn i {
        color: #000000;
    }
}
@media screen and (min-width: 1025px) {
    .m-header .m-header__bottom {
        background: #000;
    }
    .step-2-disable {
        width: 100%;
        height: 100%;
        padding: 8px 20px;
    }
    .m-menu__item .m-menu__link-icon,
    .m-menu__item .m-menu__link-text {
        color: #b1b5c1 !important;
    }
    .m-menu__item:hover .m-menu__link-icon,
    .m-menu__item:hover .m-menu__link-text {
        color: #ffffff !important;
    }
    .m-menu__item.m-menu__item--active .m-menu__link-icon,
    .m-menu__item.m-menu__item--active .m-menu__link-text {
        color: #ffffff !important;
    }
    .m-menu__link-icon,
    .m-menu__link-text {
        transition: color ease 0.3s;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .full-table-reclamation .m-portlet__head > .all-filters .filters-list label {
        text-align: left;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .full-table-reclamation .m-portlet__head > .all-filters .select-filter label {
        margin-right: 3px;
    }
}

@media (max-width: 768px){
    #ReclamationTicketFinderType .m-portlet__padding-x, #ReclamationTicketClientType .m-portlet__padding-x,
    #ReclamationTicketConfirmationType .m-portlet__padding-x, #ReclamationTicketRequestType .m-portlet__padding-x{
        padding:  0 !important;
    }
    .step-1 input, .step-3 input{
        width: 100%;
    }
    .step-1 .product-image{
        text-align: center;
    }
    .account-num{
        padding: 15px;
    }
    .client-request{
        padding-bottom: 20px;
    }
    .ReclamationTicketRequestType_videos-__videos__-tmp{
        padding-left: 0;
    }
    #ReclamationTicketRequestType_purchaseDate{
        padding: 10px;
    }
    /*.succ-icon{*/
        /*position: absolute;*/
        /*right: 0;*/
        /*top: 18%;*/
    /*}*/
    .reclamation_details_images .m-portlet__body .gallery img{
        padding: 3px;
    }

    .step-4 .acc-image img {
        max-width: 80px;
    }
    .succ-text{
        padding-left: 0;
    }
    .pdf-icon .succ-icon {
        position: absolute;
        right: 0;
        top: 18%;
    }
    .succ-title h4, .succ-text{
        font-size: 16px;
    }
    #ReclamationTicketRequestType_images .fa-plus, #ReclamationTicketRequestType_images .fa-minus {
        padding-right: .5em;
    }
    .step-2 .display-flex{
        margin: 0 15px;
        padding: 5px;
    }
    /*#m_header_menu .m-menu__link-text, #m_header_menu .m-menu__link-icon{*/
        /*color: #6c6e86 !important;*/
        /*text-transform: uppercase;*/
    /*}*/
    /*#m_header_menu .m-menu__link-text:hover, #m_header_menu .m-menu__link-icon:hover{*/
        /*color: #000 !important;*/
        /*text-transform: uppercase;*/
    /*}*/
}
.login-page {
    height: 100%;
}
.login-page > div {
    height: 100%;
}
.login-page .m-login__form{
    margin-top: 0 !important;
}
.instruction-page .m-portlet span,
.delivery-page .m-portlet span,
.terms-of-use-page .m-portlet span,
.status-page .m-portlet span,
.archive-page .m-portlet span {
    display: block;
}
.terms-of-use-page .m-portlet h3 {
    text-align: center;
    margin-bottom: 30px;
}
.terms-of-use-page a,
.instruction-page a {
    color: #555555;
    text-decoration: underline;
}
.terms-of-use-page a:hover,
.instruction-page a:hover {
    color: #000000;
}
@media screen and (max-width: 769px) {
    .terms-of-use-page,
    .instruction-page {
        text-align: justify;
    }
}
.flaticon-exclamation-1 {
    font-size: 13px !important;
}
.step-1 .steps-heading,
.step-2 .steps-heading,
.step-3 .steps-heading,
.steps-heading.final-step {
    text-align: center;
}
.step-1 .steps-heading > span:first-child,
.step-2 .steps-heading > span:first-child,
.step-3 .steps-heading > span:first-child,
.steps-heading.final-step > span:first-child {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #34bfa3;
    font-size: 24px;
    color: #34bfa3;
    font-weight: bold;
    margin-right: 16px;
    line-height: 54px;
}
.step-1 .steps-heading > span:last-child,
.step-2 .steps-heading > span:last-child,
.step-3 .steps-heading > span:last-child,
.steps-heading.final-step > span:last-child {
    font-size: 20px;
}
.step-2 .steps-heading,
.steps-heading.final-step {
    margin-bottom: 30px;
}
.step-3 .client-request > div:nth-child(2) label:nth-child(3) {
    top: 10px;
}
.step-3 .client-request label.required {
    padding-left: 26px;
}
.text-align-justify {
    text-align: justify;
}
.checkbox span::after {
    border: solid #000000;
}
#m_wizard_form_step_1 .m-checkbox::before {
    display: inline-block;
    content: "*";
    margin-right: 4px;
}
.step-1 .bottom-border + .row label.required::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
}
#ReclamationTicketClientType_note::placeholder {
    color: #aaaaaa;
}
.reclamation_details_images .cutomer-image ~ .custom-video {
    margin-top: 20px;
}
.reclamation_details_images .custom-video video {
    max-height: 300px;
    width: 480px;
    margin: 0 auto;
}
.request-creator {
    margin-top: 20px;
}
.was-on-service {
    background-color: #f6f6f6;
    padding: 4px 10px;
    width: 100%;
    text-align: center;
}
.anchor {
    color: #666666;
    text-decoration: underline;
    margin-left: 10px;
}
.anchor:hover,
.anchor:active,
.anchor:focus {
    color: #000000;
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .craue_formflow_buttons {
        padding: 0 10px !important;
    }
}

/* Spinner */
#spinner {
    margin-top: 30px;
}
#spinner i {
    font-size: 30px;
}

.login-page p {
    text-align: justify;
}
.login-page p a {
    text-decoration: underline;
    color: #555555;
}
.login-page p a:hover {
    color: #000000;
}
#m_wizard_form_step_1 .step1-term .checkbox,
#m_wizard_form_step_1 .step1-term .checkbox + a {
    display: inline-block;
}
#m_wizard_form_step_1 .step1-term .checkbox + a {
    color: #555555;
    text-decoration: underline;
}
#m_wizard_form_step_1 .step1-term .checkbox + a:hover {
    color: #000000;
    text-decoration: underline;
}
#ReclamationTicketRequestType_productReceptionFirstTime .m-radio {
    margin-bottom: 0;
}

/*.m-wizard__form .step-3 .col-xs-12 {*/
    /*padding-left: 0;*/
    /*padding-right: 0;*/
/*}*/
.step-3 .tooltip-inner {
    max-width: 400px;
}
#third-step .tooltip-inner {
    max-width: 400px;
}
#third-step textarea::placeholder {
    color: #aaaaaa;
}
#third-step .tooltip-inner a {
    color: #555555;
    text-decoration: underline;
}
#third-step .tooltip-inner a:hover {
    color: #000000;
}
div.desktop[data-trigger="click"],
div.mobile[data-trigger="click"] {
    width: fit-content;
    width: -moz-fit-content;
    cursor: pointer;
}
div[data-toggle="m-tooltip"] {
    cursor: pointer;
}
#third-step .fa-info-circle {
    font-size: 20px;
}
@media screen and (max-width: 768px) {
    .step-3 div.mobile[data-toggle="m-tooltip"] {
        display: block;
        margin: 0 auto;
        padding: 0;
        margin-bottom: 8px;
        margin-top: -10px;
    }
    .step-1 div[data-toggle="m-tooltip"] {
        margin-top: 10px;
    }
}
@media screen and (min-width: 769px) {
    .mobile {
        display: none !important;
    }
    div.desktop[data-trigger="click"] {
        display: inline-block;
        margin-left: 15px;
    }
    .step-1 div[data-toggle="m-tooltip"] {
        display: inline-block;
        width: fit-content;
        width: -moz-fit-content;
        margin-left: 8px
    }
}
@media screen and (max-width: 768px) {
    .desktop {
        display: none !important;
    }
    .login-page p {
        margin-top: 50px;
    }
}
#login-page .m-login__head {
    margin-bottom: 30px;
}
#login-page a {
    color: #666666;
    text-decoration: underline;
}
#login-page a:hover {
    color: #000000;
}
.step-1 .steps-heading {
    margin-top: 20px;
}
.tooltip-inner {
    padding: 28px 24px 20px 24px !important;
    position: relative;
}
.tooltip-inner::after {
    content: "x";
    display: block;
    position: absolute;
    top: 5px;
    right: 20px;
    font-weight: bold;
    cursor: pointer;
}
.tooltip-inner ul {
    margin: 0;
    padding: 0;
    padding-left: 4px;
}

/* Step one, warning message below the input */
#m_wizard_form_step_1 .step1-term .form-control-feedback {
    top: 4px;
    width: 465px;
    margin: 0 auto;
}
#m_wizard_form_step_1 #spinner {
    position: absolute;
    width: 100%;
}
@media (max-width: 768px) {
    #m_wizard_form_step_1 .step1-term .form-control-feedback {
        width: unset;
        text-align: center;
    }
}

/* Step one, checkbox text on mobile resolution */
@media (max-width: 768px) {
    .step1-term > div {
        width: inherit;
    }
}

@media screen and (min-width: 1024px) {
    .step-1 .steps-heading + .row #spinner + .form-control-feedback {
        width: 490px;
        padding-left: 144px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .step-1 .steps-heading + .row #spinner + .form-control-feedback {
        width: 490px;
        padding-left: 155px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 768px) {
    .step-1 .steps-heading + .row #spinner + .form-control-feedback {
        text-align: center;
    }
}

.full-table-reclamation thead tr > th:nth-child(3) {
    min-width: 160px;
}
.full-table-reclamation thead tr > th:nth-child(7) {
    width: 92px;
}

.status-page h3 {
    text-align: center;
}
/*Lista zahteva - quick fix*/
#ReclamationTicketFilterType .m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title{
    height: auto;
}
/*404 page*/
.four-o-four{
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: grid;
}
@media (min-width: 320px) {
    .four-o-four{
        min-height: 250px;
    }
    .four-o-four h1{
        font-size: 25px;
    }
}
@media (min-width: 767px) {
    .four-o-four{
        min-height: 550px;
    }
    .four-o-four h1{
        font-size: 35px;
    }
}
.four-o-four h1{
    text-align: center;
    color: #969696;

    width: 70%;
    margin-left: 15%;
}
.four-o-four  p{
    width: 70%;
    text-align: center;
    margin-left: 15%;
    padding: 15px;
    font-size: 18px;
}

#page-subheader-buttons {
    display: inline-block;
    margin-left: 20px;
}
